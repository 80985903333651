<template >
  <div id="chatvideo">
    <div fluid>
      <v-row v-if="activeVideo && activeVideo.downloadVideoUrl">
        <!-- Taken from https://www.codeply.com/p/2n5OiAvWd9 -->
        <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
          <v-responsive class="overflow-y-hidden fill-height" height="470">
            <v-card flat class="d-flex flex-column fill-height">
              <v-card-text class="flex-shrink-1">
                <v-text-field
                  v-model="newComment"
                  label="Add a comment"
                  type="text"
                  no-details
                  outlined
                  append-outer-icon="mdi-send"
                  @keyup.enter="addComment"
                  @click:append-outer="addComment"
                  hide-details
                />
              </v-card-text>
              <!-- <v-card-title>john doe</v-card-title> -->
              <v-card-text
                class="flex-grow-1 overflow-y-auto"
                v-chat-scroll="{always: false, smooth: true}"
              >
                <p v-if="messages.length == 0">Non vi sono commenti</p>
                <div>
                  <template v-for="msg in messages">
                    <div :class="{ 'd-flex flex-row-reverse': msg.me }" :key="msg.id">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-hover v-slot:default="{ hover }">
                            <v-card flat>
                              <v-list-item>
                                <Avatar
                                  style="margin-right: 16px"
                                  :userIdToAvatar="msg.userId"
                                  v-if="msg && msg.userId"
                                  :key="msg.userId"
                                />
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="caption grey--text"
                                  >{{msg.userName}} | {{ msg.timestamp }}</v-list-item-title>
                                  <v-list-item-subtitle class="body-1 comment pt-1">{{ msg.text }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <div align="center" justify="end" style="width: 60px;">
                                  <!-- <v-icon class="mr-1">mdi-heart</v-icon>
                                    <span class="subheading mr-2">256</span>
                                    <span class="mr-1">·</span>
                                    <v-icon class="mr-1">mdi-share-variant</v-icon>
                                  <span class="subheading">45</span>-->
                                  <v-icon
                                    class="mr-1"
                                    v-on="on"
                                    v-if="hover && msg.me"
                                    small
                                  >mdi-chevron-down</v-icon>
                                </div>
                              </v-list-item>
                            </v-card>
                          </v-hover>
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-list-item-title>delete</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </template>
                </div>
              </v-card-text>
            </v-card>
          </v-responsive>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-row style="height: 200px;">
            <div>
              <h2 class="font-weight-bold headline pb-2">Impossibile visualizzare i commenti</h2>
              <p
                class="font-weight-light body-2 pb-5"
              >Sottoscrivi a Techsoup Together per sbloccare funzionalitá</p>
              <!-- <v-btn color="primary" big>INIZIA ORA</v-btn> -->
              <UpgradeButton big />
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { fb, videosCollection } from "@/plugins/firebase.js";
import moment from "moment";
import UpgradeButton from "@/components/UpgradeButton.vue";
import Avatar from "@/components/Avatar.vue";

export default {
  name: "CommentVideo",

  data() {
    return {
      newComment: null,
      errorText: null,
      messages: [],
    };
  },

  components: {
    UpgradeButton,
    Avatar,
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
  },

  props: {
    activeVideo: {
      default: () => ({}),
    },
  },

  methods: {
    async addComment() {
      if (this.newComment) {
        // add comment to db
        let commentDoc = await videosCollection
          .doc(this.activeVideo.id)
          .collection("comments")
          .add({
            text: this.newComment,
            timestamp: Date.now(),
            userId: this.user.id,
            userName: this.user.name,
            created_at: fb.firestore.FieldValue.serverTimestamp(),
          })
          .catch((err) => {
            console.log(err);
          });

        this.newComment = null;
        this.errorText = null;

        // GA4 event: add goal
        this.$gtag.query('event', 'video_comment', {
          'video_id': this.activeVideo.id,
          'video_ispaid': this.activeVideo.paid_only,
          'video_istrailer': this.activeVideo.isTrailer,
          'comment_id': commentDoc.id,
          'speaker_id': this.activeVideo.main_speakerId,
          'speaker_slug': this.activeVideo.main_speaker,
        });

      } else {
        this.errorText = "A message must be entered!";
      }
    },
  },

  created() {
    videosCollection
      .doc(this.activeVideo.id)
      .collection("comments")
      .orderBy("timestamp")
      // .onSnapshot((snapshot) => {
      //   snapshot.forEach((doc) => {
      //     this.messages.push({
      //       id: doc.id,
      //       text: doc.data().text,
      //       timestamp: moment(doc.data().timestamp).format("LTS"),
      //       me: false,
      //     });
      //   });
      // });
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type == "added") {
            let doc = change.doc;
            this.messages.push({
              id: doc.id,
              text: doc.data().text,
              timestamp: moment(doc.data().timestamp).format("LLL"),
              userId: doc.data().userId,
              userName: doc.data().userName,
            });
          }
        });
      });
  },
};
</script>

<style scoped>
.comment {
  line-height: 1.2;
  text-overflow: none !important;
  white-space: none !important;
}
</style>